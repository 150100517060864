<template>
  <b-modal
    id="JoiningApplicationEducationalAddToGroup"
    scrollable
    centered
    hide-footer
    @shown="shown"
  >
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/EducationalGroups.svg" class="icon-lg" />
        {{ $t("EducationalGroupStudents.add") }}
      </h3>
    </template>

    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`JoiningApplicationEducationalAddToGroup-educationalGroupToken`"
        :value="educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="educationalGroupToken = $event"
        :title="$t('EducationalGroups.name')"
        :imgName="'EducationalGroups.svg'"
      />
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="addStudents"
      >
        {{ $t("ok") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="
          $bvModal.hide('JoiningApplicationEducationalAddToGroup')
        "
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import { STATUS } from "./../../../../utils/constants";
import {
  TERMINATION_TYPES,
  CLOSED_STATE_TYPES,
} from "./../../../../utils/constantLists";
import apiEducationalGroupStudent from "./../../../../api/educational/educationalGroupStudents";
import generalMixin from "./../../../../utils/generalMixin";
import { getEducationalGroupsDialogCustomized } from "./../../../../utils/dialogsOfApi";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomSelectBox,
  },
  data() {
    return {
      educationalGroupToken: null,
      educationalGroupTokenOptions: [],
    };
  },
  props: ["joiningApplicationEducational"],
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  methods: {
    async addStudents() {
      this.isLoading = true;
      let params = {
        userAuthorizeToken: this.userAuthorizeToken,
        educationalGroupToken: this.educationalGroupToken,
        educationalJoiningApplicationTokens: [
          this.joiningApplicationEducational.educationalJoiningApplicationToken,
        ],
      };

      try {
        const response = await apiEducationalGroupStudent.addStudents(params);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("JoiningApplicationEducationalAddToGroup");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    async getEducationalGroupsDialog() {
      this.isLoading = true;
      let params = {
        educationalGroupTerminationTypeToken: TERMINATION_TYPES.NotFinished,
        educationalGroupClosedStateTypeToken: CLOSED_STATE_TYPES.Open,
        educationalCategoryToken:
          this.joiningApplicationEducational.educationalCategoryToken,
      };
      this.educationalGroupTokenOptions =
        await getEducationalGroupsDialogCustomized(params);
      this.isLoading = false;
    },
    shown() {
      this.educationalGroupToken = null;
      this.getEducationalGroupsDialog();
    },
  },
  async created() {},
};
</script>

import GeneralModelResponse from "./../../general/GeneralModelResponse";
import JoiningApplicationEducationalStatistics from "./JoiningApplicationEducationalStatistics";

export default class JoiningApplicationEducationalGeneralModel extends GeneralModelResponse {
  constructor() {
    super();
  }
  setGeneralModelInitialValue() {
    this.setGeneralModelResponseInitialValue();
    this.joiningApplicationEducationalsStatistics =
      new JoiningApplicationEducationalStatistics();
  }
  fillGeneralModelData(data) {
    if (data) {
      this.fillGeneralModelResponseData(data);
      this.joiningApplicationEducationalsStatistics.fillJoiningApplicationEducationalStatisticsData(
        data.joiningApplicationEducationalStatistics
      );
    } else {
      this.setGeneralModelInitialValue();
    }
  }
}

import { METHOD, request } from "./../../utils/request";
import { JoiningApplicationEducationals } from "./../api";

async function getAll(params) {
  return request(JoiningApplicationEducationals.getAll, METHOD.GET, params);
}
async function getReport(params) {
  return request(JoiningApplicationEducationals.getReport, METHOD.GET, params);
}
async function changePostponedStatus(data) {
  return request(
    JoiningApplicationEducationals.changePostponedStatus,
    METHOD.POST,
    data
  );
}

async function getTree(params) {
  return request(JoiningApplicationEducationals.getTree, METHOD.GET, params);
}

export default {
  getAll,
  getReport,
  changePostponedStatus,
  getTree,
};

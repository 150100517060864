export default class JoiningApplicationEducationalStatistics {
  constructor() {
    this.setJoiningApplicationEducationalStatisticsInitialValue();
  }
  setJoiningApplicationEducationalStatisticsInitialValue() {
    this.totalJoiningApplication = 0;
    this.totalCountIsNotRelatedWithGroup = 0;
    this.totalCountIsRelatedWithGroup = 0;
    this.totalCountIsFreezed = 0;
    this.totalCountIsPostponed = 0;
  }
  fillJoiningApplicationEducationalStatisticsData(data) {
    if (data) {
      this.totalJoiningApplication = data.totalJoiningApplication ?? 0;
      this.totalCountIsNotRelatedWithGroup =
        data.totalCountIsNotRelatedWithGroup ?? 0;
      this.totalCountIsRelatedWithGroup =
        data.totalCountIsRelatedWithGroup ?? 0;
      this.totalCountIsFreezed = data.totalCountIsFreezed ?? 0;
      this.totalCountIsPostponed = data.totalCountIsPostponed ?? 0;
    } else {
      this.setJoiningApplicationEducationalStatisticsInitialValue();
    }
  }
}

<template>
  <div class="">
    <DashboardNavbar
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <JoiningApplicationEducationalTabs
        :joiningApplicationEducationalsStatistics="
          joiningApplicationEducationals.joiningApplicationEducationalsStatistics
        "
        :theFilterData="joiningApplicationEducationals.filterData"
        v-on:search="search($event)"
        v-on:currentTabUpdated="currentTab = $event"
      />

      <template v-if="hasData">
        <JoiningApplicationEducationalTable
          :joiningApplicationEducationalsData="
            joiningApplicationEducationals.joiningApplicationEducationalsData
          "
          :defaultImg="
            joiningApplicationEducationals.joiningApplicationEducational
              .defaultImg
          "
          :filterData="joiningApplicationEducationals.filterData"
          :currentTab="currentTab"
          v-on:setJoiningApplicationEducationalData="
            joiningApplicationEducationals.joiningApplicationEducational.fillData(
              $event
            )
          "
          v-on:makeNotFreezed="
            makeNotFreezed($event.educationalGroupStudentToken)
          "
        />
        <JoiningApplicationEducationalAddToGroup
          :joiningApplicationEducational="
            joiningApplicationEducationals.joiningApplicationEducational
          "
          @refresh="getAllJoiningApplicationEducationals()"
        />
        <JoiningApplicationEducationalChangePostponedStatus
          :joiningApplicationEducational="
            joiningApplicationEducationals.joiningApplicationEducational
          "
          :currentTab="currentTab"
          @refresh="getAllJoiningApplicationEducationals()"
        />

        <EducationalGroupStudentFreeze
          :educationalGroupStudentToken="
            joiningApplicationEducationals.joiningApplicationEducational
              .educationalGroupStudentToken
          "
          v-on:refresh="getAllJoiningApplicationEducationals()"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="joiningApplicationEducationals.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <JoiningApplicationEducationalFilter
        :theFilterData="joiningApplicationEducationals.filterData"
        :currentTab="currentTab"
        v-on:search="search($event)"
      />
    </div>
  </div>
</template>

<script>
import { STATUS } from "./../../../utils/constants";
import { FREEZE_STATUS_TYPE } from "./../../../utils/constantLists";
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import CustomPagination from "./../../../components/general/CustomPagination.vue";
import JoiningApplicationEducationalTabs from "./components/JoiningApplicationEducationalTabs.vue";
import JoiningApplicationEducationalFilter from "./components/JoiningApplicationEducationalFilter.vue";
import JoiningApplicationEducationalTable from "./components/JoiningApplicationEducationalTable.vue";
import JoiningApplicationEducationalAddToGroup from "./components/JoiningApplicationEducationalAddToGroup.vue";
import JoiningApplicationEducationalChangePostponedStatus from "./components/JoiningApplicationEducationalChangePostponedStatus.vue";
import EducationalGroupStudentFreeze from "./../educationalGroupStudents/components/EducationalGroupStudentFreeze.vue";
import JoiningApplicationEducationals from "./../../../models/educational/joiningApplicationEducationals/JoiningApplicationEducationals";
import apiJoiningApplicationEducational from "./../../../api/educational/joiningApplicationEducationals";
import apiEducationalGroupStudent from "./../../../api/educational/educationalGroupStudents";
import generalMixin from "./../../../utils/generalMixin";
import generalSettingsMixin from "./../../../utils/generalSettingsMixin";
import { checkPrivilege, objectToFormData } from "./../../../utils/functions";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  props: {},
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    CustomPagination,
    JoiningApplicationEducationalTabs,
    JoiningApplicationEducationalFilter,
    JoiningApplicationEducationalTable,
    JoiningApplicationEducationalAddToGroup,
    JoiningApplicationEducationalChangePostponedStatus,
    EducationalGroupStudentFreeze,
  },
  computed: {
    hasData() {
      return (
        this.joiningApplicationEducationals.joiningApplicationEducationalsData
          .length != 0
      );
    },
  },
  data() {
    return {
      joiningApplicationEducationals: new JoiningApplicationEducationals(),
      currentTab: "",
    };
  },
  methods: {
    checkPrivilege,
    changePagination(pagination) {
      this.joiningApplicationEducationals.filterData.fillData(pagination);
      this.getAllJoiningApplicationEducationals();
    },
    search(data) {
      this.joiningApplicationEducationals.filterData.fillData(data);
      this.getAllJoiningApplicationEducationals();
    },
    async makeNotFreezed(educationalGroupStudentToken) {
      this.isLoading = true;
      let params = {
        educationalGroupStudentToken: educationalGroupStudentToken,
        freezeStatusToken: FREEZE_STATUS_TYPE.NotFreezed,
      };
      let formData = objectToFormData(params);
      try {
        const response = await apiEducationalGroupStudent.changeFreezeStatus(
          formData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg, false);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
      this.getAllJoiningApplicationEducationals();
    },
    async getAllJoiningApplicationEducationals() {
      this.isLoading = true;
      try {
        this.joiningApplicationEducationals.joiningApplicationEducationalsData =
          [];
        const response = await apiJoiningApplicationEducational.getAll(
          this.joiningApplicationEducationals.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.joiningApplicationEducationals.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
    setFilter() {},
  },
  async created() {
    await this.setFilter();
    this.getAllJoiningApplicationEducationals();
  },
};
</script>

import EducationalGroupInfo from "./EducationalGroupInfo";

export default class EducationalGroupJoining {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.educationalGroupStudentToken = "";
    this.educationalGroupInfo = new EducationalGroupInfo();
    this.certificateStatusToken = "";
    this.certificateStatusNameCurrent = "";
    this.freezeStatusToken = "";
    this.freezeStatusNameCurrent = "";
    this.freezedToDateTime = "";
    this.freezedToDateTimeCustomized = "";
    this.freezedToDate = "";
    this.freezedToTime = "";
    this.freezedNotes = "";
    this.placementDateTime = "";
    this.placementDateTimeCustomized = "";
    this.placementDate = "";
    this.placementTime = "";
  }
  fillData(data) {
    if (data) {
      this.educationalGroupStudentToken =
        data.educationalGroupStudentToken ?? "";
      this.educationalGroupInfo.fillData(data.educationalGroupInfo);
      this.certificateStatusToken = data.certificateStatusToken ?? "";
      this.certificateStatusNameCurrent =
        data.certificateStatusNameCurrent ?? "";
      this.freezeStatusToken = data.freezeStatusToken ?? "";
      this.freezeStatusNameCurrent = data.freezeStatusNameCurrent ?? "";
      this.freezedToDateTime = data.freezedToDateTime ?? "";
      this.freezedToDateTimeCustomized = data.freezedToDateTimeCustomized ?? "";
      this.freezedToDate = data.freezedToDate ?? "";
      this.freezedToTime = data.freezedToTime ?? "";
      this.freezedNotes = data.freezedNotes ?? "";
      this.placementDateTime = data.placementDateTime ?? "";
      this.placementDateTimeCustomized = data.placementDateTimeCustomized ?? "";
      this.placementDate = data.placementDate ?? "";
      this.placementTime = data.placementTime ?? "";
    } else {
      this.setInitialValue();
    }
  }
}

import JoiningApplicationEducationalGeneralModel from "./JoiningApplicationEducationalGeneralModel";
import JoiningApplicationEducational from "./JoiningApplicationEducational";
import JoiningApplicationEducationalsFilter from "./JoiningApplicationEducationalsFilter";

export default class JoiningApplicationEducationals extends JoiningApplicationEducationalGeneralModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setGeneralModelInitialValue();
    this.joiningApplicationEducationalsData = [];
    this.joiningApplicationEducational = new JoiningApplicationEducational();
    this.filterData = new JoiningApplicationEducationalsFilter();
  }
  fillData(data) {
    if (data) {
      this.fillGeneralModelData(data);
      this.joiningApplicationEducationalsData =
        data.joiningApplicationEducationalsData ?? [];
      let filter = { ...this.filterData, ...data.pagination };
      this.filterData.fillData(filter);
    } else {
      this.setInitialValue();
    }
  }
}
